import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from './../../components/components.module';
import { TeamComponent } from './team.component';

@NgModule({
	declarations: [TeamComponent],
	imports: [ComponentsModule, RouterModule]
})
export class TeamModule {
}
