import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from './../../components/components.module';
import { ImmigrationComponent } from './immigration.component';

@NgModule({
	declarations: [ImmigrationComponent],
	imports: [ComponentsModule, RouterModule, CommonModule]
})
export class ImmigrationModule {
}
