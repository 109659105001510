import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from './../../components/components.module';
import { CareersComponent } from './careers.component';

@NgModule({
	declarations: [CareersComponent],
	imports: [ComponentsModule, RouterModule]
})
export class CareersModule {
}
