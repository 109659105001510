import { Routes } from '@angular/router';

import { HomeComponent } from './modules/home/home.component';
import { ImmigrationComponent } from './modules/immigration/immigration.component';
import { FirmComponent } from './modules/firm/firm.component';
import { CareersComponent } from './modules/careers/careers.component';
import { TeamComponent } from './modules/team/team.component';

export const appRoutes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: 'immigration', component: ImmigrationComponent },
	{ path: 'our-firm', component: FirmComponent },
	{ path: 'careers', component: CareersComponent },
	{ path: 'team', component: TeamComponent },
	{ path: '*', redirectTo: '', pathMatch: 'full' }
];
