import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routes';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { HomeModule } from './modules/home/home.module';
import { ImmigrationModule } from './modules/immigration/immigration.module';
import { FirmModule } from './modules/firm/firm.module';
import { CareersModule } from './modules/careers/careers.module';
import { TeamModule } from './modules/team/team.module';

@NgModule({
	declarations: [
		AppComponent
	],
 	imports: [
		BrowserModule,
		RouterModule.forRoot(appRoutes),
		ComponentsModule,
		HomeModule,
		ImmigrationModule,
		FirmModule,
		CareersModule,
		TeamModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
