import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from './../../components/components.module';
import { FirmComponent } from './firm.component';

@NgModule({
	declarations: [FirmComponent],
	imports: [ComponentsModule, RouterModule]
})
export class FirmModule {
}
