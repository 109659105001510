import { Component } from '@angular/core';

@Component({
	templateUrl: './immigration.component.html',
	styleUrls: ['./immigration.component.css']
})
export class ImmigrationComponent {
	activeNonImmigrantAccordion = 1;
	activeImmigrantAccordion = 1;

	constructor() { }

	onChangeNonImmigrantAccordion(id) {
		this.activeNonImmigrantAccordion = id;
	}

	onChangeImmigrantAccordion(id) {
		this.activeImmigrantAccordion = id;
	}
}
